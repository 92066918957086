
import Vue from 'vue'

import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'

import StatusColor from './StatusColor.vue'
import RepeatInvoices from './repeat-datatable/RepeatInvoicesTable.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { Notify } from '@/globals/controllers/Notification'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import FilterDate from '@/globals/components/filters/filter-date/FilterDate.vue'
import HorizontalTabbar from './tabbar/HorizontalTabbar.vue'
import Tooltip from '@/globals/components/tooltip/Tooltip.vue'
import SelectionActionButton from './selection-action-button/SelectionActionButton.vue'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { TablePaginationModel } from '@/globals/models/TablePagination'
import { InvoiceTableFilter } from '@/modules/invoicing/models/invoice/InvoiceTableFilter'
import { InvoiceTableTab } from '@/modules/invoicing/enums/InvoiceTableTab'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'
import RowActionButton from './row-action-button/RowActionButton.vue'
import { DataTableHeader } from 'vuetify'
import { InvoiceDataTableItem } from '@/modules/invoicing/enums/InvoiceDataTableItem'

export default Vue.extend({
  props: {
    invoicePrefix: {
      type: String,
      required: false
    }
  },
  components: {
    InputField,
    PrimaryButton,
    BlankSlate,
    StatusColor,
    HorizontalTabbar,
    RepeatInvoices,
    Dropdown,
    FilterDate,
    Tooltip,
    SelectionActionButton,
    IconBase,
    IconPlus,
    IconLookingGlass,
    RowActionButton
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      relationStore: this.$store.state.relation,
      dialogStore: this.$store.state.dialog,
      invoiceStatus: new InvoiceStatus() as InvoiceStatus,
      dateHelper,
      selected: [],
      selectedFilterDates: null as null|Array<Date|null>,
      selectedFilterDate: 0,
      selectedFilterExpired: null,
      search: '',
      tabs: [
        { value: InvoiceTableTab.ALL, name: 'Alles' },
        { value: InvoiceTableTab.CONCEPT, name: 'Concept' },
        { value: InvoiceTableTab.SENT, name: 'Verzonden' },
        { value: InvoiceTableTab.EXPIRED, name: 'Vervallen' },
        { value: InvoiceTableTab.PAID, name: 'Betaald' },
        { value: InvoiceTableTab.REPEAT_INVOICES, name: 'Herhaalfacturen' }
      ],
      headers: [
        { text: '#', align: 'start', value: 'number', width: 160, class: 'header--text' },
        { text: 'KLANT', value: 'name', width: 150, class: 'header--text' },
        { text: 'ONDERWERP', align: 'start', value: 'subject', width: 'auto', class: 'header--text' },
        { text: 'FACTUURDATUM', value: 'invoice_date', width: 140, class: 'header--text' },
        { text: 'TOTAALBEDRAG', value: 'total_price', align: 'end', width: 140, class: 'header--text' },
        { text: 'STATUS', value: 'status_text', width: 150, class: 'header--text' },
        { text: '', value: 'actions', sortable: false, width: 20 },
        { text: '', value: 'search', sortable: false, width: 0, class: 'header--hidden' }
      ] as DataTableHeader[],
      sortBy: ['invoice_date'],
      sortDesc: [true],
      tableItems: [] as any[],
      recurrenceInvoices: [] as Array<any>,
      recurrenceCount: 0
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    sweet (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    invoices: {
      get (): Array<Invoice> {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Array<Invoice>) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    tablePagination (): TablePaginationModel {
      return this.$store.getters['invoicing/tablePagination']
    },
    filter: {
      get (): InvoiceTableFilter {
        return this.$store.getters['invoicing/filter']
      },
      set (filter: InvoiceTableFilter) {
        this.$store.dispatch('invoicing/setFilter', filter)
        return filter
      }
    },
    isRecurrenceInvoicesShown (): boolean {
      return this.filter.tab === InvoiceTableTab.REPEAT_INVOICES && (this.essentialsLoaded && this.invoices.length > 0)
    },
    filteredData (): Array<any> {
      return this.tableItems.filter((item) => {
        let isValid = this.filterInvoiceExpired(item)
        isValid = isValid ? this.filterDates(item) : false

        return isValid
      })
    },
    filteredInvoices (): Array<any> {
      return this.filteredData.filter((item) => {
        return this.filterTabs(item.invoice)
      }).sort((a: any, b: any) => {
        return a.number < b.number ? 1 : -1
      })
    },
    selectedInvoices (): Array<Invoice> {
      return Array.from(this.selected, (item: any) => item.invoice)
    }
  },
  watch: {
    invoices: {
      handler: function () {
        arrayHelper.clear(this.tableItems)
        this.setTableItems(this.invoices)
      },
      deep: true
    },
    selectedFilterExpired () {
      this.setFilterinvoiceExpired(this.selectedFilterExpired)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false
      this.setFilters()

      if (this.invoices.length > 1) {
        this.setTableItems(this.invoices)
      } else {
        await this.fetchInvoices()
      }
      this.essentialsLoaded = true
    },
    setFilters () {
      this.setFilterInvoiceDate()
      this.setFilterInvoiceExpired()
    },
    setFilterInvoiceDate () {
      const date = this.filter.date
      const from = this.filter.from
      const to = this.filter.to

      if (date) {
        this.selectedFilterDates = [] as Array<Date>
        this.selectedFilterDate = parseInt(date)

        this.selectedFilterDates[0] = from ? dateHelper.resetTime(from) : null
        this.selectedFilterDates[1] = to ? dateHelper.resetTime(to) : null
      }
    },
    setFilterInvoiceExpired () {
      this.selectedFilterExpired = this.filter.invoiceExpired
    },

    async fetchInvoices () {
      await apiInvoiceService.getAllInvoices().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const invoicesRaw = response.data as Array<any>
          const invoices = [] as Array<Invoice>

          invoicesRaw.forEach(invoiceRaw => {
            const invoice = new Invoice().fromResponse(invoiceRaw)
            invoices.push(invoice)
          })
          this.invoices = invoices
        }
      })
    },
    setTableItems (invoices: Array<Invoice>) {
      invoices.forEach(invoice => {
        if (invoice.status !== this.invoiceStatus.recurrence_template.value) {
          this.insertInvoice(invoice)
        }
      })
    },
    insertInvoice (invoice: Invoice) {
      const number = invoice.getInvoiceNumber(this.relation.invoice_prefix)
      const name = invoice.to_relation ? invoice.to_relation.getRelationName() : ''
      const updatedAtUnix = dateHelper.toUnix(new Date(invoice.updated_at))
      const invoiceDateUnix = dateHelper.toUnix(new Date(invoice.invoice_date))
      const invoiceDateText = dateHelper.toShortMonthDateString(invoice.invoice_date)
      const status = this.getInvoiceStatusText(invoice)
      const totalPrice = priceHelper.getLocalPrice(invoice.total)

      let productNames = ''

      for (let i = 0; i < invoice.products.length; i++) {
        productNames += invoice.products[i].title
      }

      const tableItem = {
        id: invoice.id,
        number: this.isRecurrenceTemplate(invoice) ? 'Basis template' : number,
        name,
        subject: invoice.description,
        updatedAtUnix,
        invoice_date: invoiceDateUnix,
        invoice_date_text: invoiceDateText,
        total_price: '€ ' + totalPrice,
        status: invoice.status,
        status_text: status,
        isSelectable: true,
        invoiceStatus: this.invoiceStatus.getInvoiceStateByValue(invoice.status),
        search: invoice.reference + invoice.description + productNames,
        created_at_date: dateHelper.resetTime(invoice.invoice_date),
        invoice
      }

      this.tableItems.push(tableItem)
    },

    getInvoiceStatusText (invoice: Invoice): string {
      let value = this.invoiceStatus.getTextByValue(invoice.status)

      if (invoice.isExpired() && invoice.status === this.invoiceStatus.sent.value) {
        value = 'Vervallen'
      }

      if (invoice.reminder_sent > 0 && invoice.status === this.invoiceStatus.sent.value) {
        value = 'Herinnerd (' + invoice.reminder_sent + ')'
      }

      return value
    },

    filterTabs (invoice: Invoice) {
      if (this.filter.tab === InvoiceTableTab.PAID) {
        return invoice.status === this.invoiceStatus.fully_paid.value
      } else if (this.filter.tab === InvoiceTableTab.EXPIRED) {
        return invoice.isExpired() && invoice.status === this.invoiceStatus.sent.value
      } else if (this.filter.tab === InvoiceTableTab.SENT) {
        return invoice.status === this.invoiceStatus.sent.value || invoice.status === this.invoiceStatus.partially_paid.value
      } else if (this.filter.tab === InvoiceTableTab.CONCEPT) {
        return invoice.status === this.invoiceStatus.concept.value
      } else if (this.filter.tab === InvoiceTableTab.REPEAT_INVOICES) {
        return invoice.status === this.invoiceStatus.recurrence_template.value
      } else {
        return true
      }
    },
    filterInvoiceExpired (item: any) {
      if (this.selectedFilterExpired === null) {
        return true
      } else if (this.selectedFilterExpired && item.invoice.isExpired()) {
        return true
      } else if (!this.selectedFilterExpired && !item.invoice.isExpired()) {
        return true
      } else {
        return false
      }
    },
    filterDates (item: any) {
      let valid = false
      if (this.selectedFilterDates === null) {
        valid = true
      }
      if (this.selectedFilterDates !== null && this.selectedFilterDates[0] !== null && this.selectedFilterDates[0] <= item.created_at_date) {
        if (this.selectedFilterDates[1] === null) {
          valid = true
        }
        if (this.selectedFilterDates[1] !== null && this.selectedFilterDates[1] >= item.created_at_date) {
          valid = true
        }
      }

      return valid
    },

    isInvoicesStatusAllConcept (invoices: Array<any>): boolean {
      let isConcept = true

      invoices.forEach(invoice => {
        if (!this.isConceptState(invoice.status)) { isConcept = false }
      })

      return isConcept
    },
    isConceptState (status: number) {
      return this.invoiceStatus.concept.value === status
    },

    onTabChange (value: InvoiceTableTab) {
      this.tablePagination.page = 1
      this.filter.tab = value
      this.updateFilter()
    },
    updateFilter () {
      this.$store.dispatch('invoicing/setFilter', this.filter)
    },

    isRecurrenceTemplate (invoice: Invoice): boolean {
      return invoice.id === invoice.recurrence_id
    },

    async fetchRecurrenceInvoices () {
      await apiInvoiceService.getRecurrenceInvoices().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.recurrenceInvoices = response.data
        }
      })
    },

    async deleteInvoice (invoice: Invoice) {
      const number = invoice.getInvoiceNumber(this.relation.invoice_prefix)
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je ' + number + ' wilt verwijderen?', subMessage: 'Dit kan niet ongedaan gemaakt worden.', isDeleting: true })

      if (isConfirmed) {
        await apiInvoiceService.deleteInvoice(invoice.id).then((response: any) => {
          if (response.status === HttpStatus.DELETED) {
            const index = this.invoices.findIndex(item => item.id === invoice.id)
            this.invoices.splice(index, 1)
            new Notify().success('Gelukt!', 'Factuur ' + number + ' is verwijderd')
          }
        })
      }
    },

    async duplicateInvoice (invoice: Invoice) {
      const number = invoice.getInvoiceNumber(this.relation.invoice_prefix)
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Factuur ' + number + ' dupliceren?', agreeButtonText: 'Dupliceer' })

      if (isConfirmed) {
        await apiInvoiceService.copyInvoice(invoice.id).then((response: any) => {
          if (response.status === HttpStatus.CREATED) {
            const invoice = new Invoice().fromResponse(response.data.invoice)
            this.invoices.push(invoice)
            this.goToInvoice(invoice)
            new Notify().success('Gelukt!', 'Factuur ' + number + ' is gedupliceerd')
          }
        })
      }
    },

    async reloadTableData () {
      arrayHelper.clear(this.invoices)
      await this.loadEssentials()
    },

    goToInvoice (invoice: Invoice) {
      if (invoice.id !== null) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id: invoice.id.toString() } })
      }
    },

    navigateToCreateInvoice () {
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.create.name })
    },

    setFilterDateQuery (data: null|{ dates: Array<Date>; selected: number}) {
      const selected = data ? data.selected : null
      const from = data ? dateHelper.toDateString(data.dates[0]) : null
      const to = data && data.dates[1] ? dateHelper.toDateString(data.dates[1]) : null

      this.$store.commit('invoicing/setFilterDate', selected)
      this.$store.commit('invoicing/setFilterDateFrom', from)
      this.$store.commit('invoicing/setFilterDateTo', to)
    },

    setFilterinvoiceExpired (isExpired: boolean|null) {
      this.$store.commit('invoicing/setFilterinvoiceExpired', isExpired)
    },

    async creditInvoice (invoice: Invoice) {
      const number = invoice.getInvoiceNumber(this.relation.invoice_prefix)
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet zeker dat je factuur ' + number + ' wil crediteren?', agreeButtonText: 'Crediteer' })
      if (isConfirmed) {
        await apiInvoiceService.creditInvoice(invoice.id).then((response: any) => {
          if (response.status === HttpStatus.CREATED) {
            const invoice = new Invoice().fromResponse(response.data.invoice)
            this.invoices.push(invoice)
            new Notify().success('Gelukt!', 'Gecrediteerd factuur is aangemaakt')
            this.goToInvoice(invoice)
          }
        })
      }
    },

    setTablePagination (tablePagination: TablePaginationModel) {
      this.$store.dispatch('invoicing/setTablePagination', new TablePaginationModel(tablePagination))
    },

    customSort (items: InvoiceDataTableItem[], sortBy: Array<string>, sortDesc: any): any {
      return items.slice().sort((a, b) => {
        const order = sortDesc[0] ? -1 : 1

        if (sortBy[0] === 'invoice_date') {
          return this.sortInvoiceDate(a, b, order)
        }

        if (sortBy[0] === 'number') {
          return this.sortNumber(a, b, order)
        }

        if (sortBy[0] === 'name' || sortBy[0] === 'subject' || sortBy[0] === 'status_text') {
          const textA = a[sortBy[0]]?.toString().toLowerCase() || ''
          const textB = b[sortBy[0]]?.toString().toLowerCase() || ''
          return textA.localeCompare(textB) * order
        }

        if (sortBy[0] === 'total_price') {
          const totalPriceA = a.invoice.total || 0
          const totalPriceB = b.invoice.total || 0
          return (totalPriceA - totalPriceB) * order
        }

        // Default sorting for other columns
        const valueA = (a as any)[sortBy[0]]
        const valueB = (b as any)[sortBy[0]]
        return valueA > valueB ? 1 * order : valueA < valueB ? -1 * order : 0
      })
    },

    sortInvoiceDate (a: InvoiceDataTableItem, b: InvoiceDataTableItem, order: number) {
      if (a.invoice_date < b.invoice_date) return -1 * order
      if (a.invoice_date > b.invoice_date) return order

      // If invoice_date is the same, sort by number (prefix_id)
      return (parseInt(a.invoice.prefix_id?.toString() || '0') - parseInt(b.invoice.prefix_id?.toString() || '0')) * order
    },
    sortNumber (a: InvoiceDataTableItem, b: InvoiceDataTableItem, order: number) {
      if (a.invoice.isStatusConcept() && !b.invoice.isStatusConcept()) {
        return -1
      }
      if (!a.invoice.isStatusConcept() && b.invoice.isStatusConcept()) {
        return 1
      }

      const yearA = new Date(a.invoice.invoice_date).getFullYear()
      const yearB = new Date(b.invoice.invoice_date).getFullYear()

      if (yearA !== yearB) {
        return (yearA - yearB) * order
      }

      // If years are the same, sort by invoice number
      const numberA = parseInt(a.invoice.prefix_id?.toString() || '0')
      const numberB = parseInt(b.invoice.prefix_id?.toString() || '0')

      return (numberA - numberB) * order
    }
  }
})
