import { render, staticRenderFns } from "./ChangePasswordForm.vue?vue&type=template&id=6a957b9b&scoped=true&"
import script from "./ChangePasswordForm.vue?vue&type=script&lang=ts&"
export * from "./ChangePasswordForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_webpack@5.74.0__ejs@3.1.8_lodash@4.17.21_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a957b9b",
  null
  
)

export default component.exports