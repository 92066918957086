import { render, staticRenderFns } from "./FullscreenModal.vue?vue&type=template&id=3947357d&scoped=true&"
import script from "./FullscreenModal.vue?vue&type=script&lang=ts&"
export * from "./FullscreenModal.vue?vue&type=script&lang=ts&"
import style0 from "./FullscreenModal.vue?vue&type=style&index=0&id=3947357d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_webpack@5.74.0__ejs@3.1.8_lodash@4.17.21_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3947357d",
  null
  
)

export default component.exports