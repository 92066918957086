import { render, staticRenderFns } from "./RegisterComponent.vue?vue&type=template&id=d46657e0&scoped=true&"
import script from "./RegisterComponent.vue?vue&type=script&lang=ts&"
export * from "./RegisterComponent.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterComponent.vue?vue&type=style&index=0&id=d46657e0&prod&lang=scss&"
import style1 from "./RegisterComponent.vue?vue&type=style&index=1&id=d46657e0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_webpack@5.74.0__ejs@3.1.8_lodash@4.17.21_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d46657e0",
  null
  
)

export default component.exports