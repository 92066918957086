import { render, staticRenderFns } from "./CreateRelation.vue?vue&type=template&id=72f78630&scoped=true&"
import script from "./CreateRelation.vue?vue&type=script&lang=ts&"
export * from "./CreateRelation.vue?vue&type=script&lang=ts&"
import style0 from "./CreateRelation.vue?vue&type=style&index=0&id=72f78630&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_webpack@5.74.0__ejs@3.1.8_lodash@4.17.21_vue-template-compiler@2.7.13_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f78630",
  null
  
)

export default component.exports