export interface Options {
    text: string;
    value: number;
}
export class InvoiceStatus {
  recurrence_template: Options = {
    text: 'Basis template',
    value: 0
  }

  unknown1: Options = {
    text: 'unknown',
    value: 1
  }

  unknown2: Options = {
    text: 'unknown',
    value: 2
  }

  concept: Options = {
    text: 'Concept',
    value: 3
  }

  sent: Options = {
    text: 'Verzonden',
    value: 5
  }

  partially_paid: Options = {
    text: 'Gedeeltelijk betaald',
    value: 6
  }

  fully_paid: Options = {
    text: 'Betaald',
    value: 7
  }

  getInvoiceStates () {
    return [
      this.recurrence_template,
      this.unknown1,
      this.unknown2,
      this.concept,
      this.sent,
      this.partially_paid,
      this.fully_paid
    ]
  }

  getInvoiceStateByValue (value: number) {
    const invoiceStates = this.getInvoiceStates()

    let state = this.concept

    invoiceStates.forEach((invoiceState: any) => {
      if (invoiceState.value === value) {
        state = invoiceState
      }
    })

    return state
  }

  getInvoiceStateByText (text: string) {
    const invoiceStates = this.getInvoiceStates()

    let state = this.recurrence_template

    invoiceStates.forEach((invoiceState: any) => {
      if (invoiceState.text === text) {
        state = invoiceState
      }
    })

    return state
  }

  getTextByValue (value: number) {
    const invoiceState = this.getInvoiceStateByValue(value) as any

    return invoiceState.text
  }

  getValueByText (text: string) {
    const invoiceState = this.getInvoiceStateByText(text) as any

    return invoiceState.value
  }
}
